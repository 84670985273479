export function utils(list, this_, type = 1) {
  console.log('utils fun', list, this_)

  if (list.length == 0) {
    return false
  }
  let dgze = 0,
    xsje = 0,
    thje = 0,
    yh = 0,
    yhh = 0,
    thtk = 0,
    ysk = 0,
    fydk = 0

  if (
    this_.form.support_amount != '' ||
    this_.form.support_amount != undefined ||
    this_.form.support_amount != 0
  ) {
    fydk = Number(this_.form.support_amount)
  }
  if (
    this_.form.discounted_amount != '' ||
    this_.form.discounted_amount != undefined ||
    this_.form.discounted_amount != 0
  ) {
    yhh = Number(this_.form.discounted_amount)
  }
  if (
    this_.form.discount_amount != '' ||
    this_.form.discount_amount != undefined ||
    this_.form.discount_amount != 0
  ) {
    yh = Number(this_.form.discount_amount)
  }
  list.forEach((item) => {
    if (type == 2) {
      var num = Number(
        (Number(item.goods_price) * Number(item.quantity2)).toFixed(2)
      )
    } else if (type == 3) {
      var num = Number(
        (Number(item.goods_price) * Number(item.pre_quantity)).toFixed(2)
      )
    } else {
      var num = Number(
        (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
      )
    }
    // 订购总额
    // 销常
    if (
      item.goods_type == 1 ||
      item.goods_type == 17 ||
      item.goods_type == 18
    ) {
      dgze += num
      xsje += num
      // yhh += num
    }
    if (item.goods_type == 6 || item.goods_type == 19) {
      thje += num
      dgze -= num
      ysk -= Number(num)
      // this.form.thje =
    }
    if (item.goods_type == 7) {
      dgze += num
      // this.form.thje =
    }
    if (item.goods_type == 8) {
      dgze += num
      // this.form.thje =
    }
    if (this_.form.refund_type == 1) {
      thtk = thje
    } else {
      thtk = 0
    }
    this_.form.discounted_amount = yhh
    // 应收款
    if (
      (this_.form.discounted_amount == 0 ||
        this_.form.discounted_amount == '') &&
      item.goods_type == 6
    ) {
      console.log('yhh 是0 ', this_.form.discounted_amount)
      ysk = 0
    } else {
      yhh = Number(xsje) - Number(yh)
      console.log('yhh bushi是0 ', xsje, yh, yhh, thtk, dgze)
      let c = JSON.parse(JSON.stringify(yhh)) - JSON.parse(JSON.stringify(thtk))
      console.log('c', c.toFixed(2))
      let a = c.toFixed(2)
      console.log(ysk, fydk)
      ysk = (
        Number(a) -
        Number(fydk) -
        (this_.form.prize_amount != undefined
          ? Number(this_.form.prize_amount)
          : 0) -
        (this_.form.cash_amount != undefined
          ? Number(this_.form.cash_amount)
          : 0)
      ).toFixed(2)
    }
  })
  this_.form.receiv_money = Number(ysk).toFixed(2)
  this_.form.discounted_amount = Number(yhh).toFixed(2)
  this_.form.refund_amount = Number(thtk).toFixed(2)
  this_.form.refund_goods_amount = Number(thje).toFixed(2)
  this_.form.total_amount = Number(dgze).toFixed(2)
  this_.form.sale_amount = Number(xsje).toFixed(2)

  if (this_.form.refund_type != 1) {
    console.log('销售金额', xsje)
    this_.form.total_amount = Number(xsje).toFixed(2)
  }
  return {
    ysk,
    yhh,
    thtk,
    thje,
    dgze,
    xsje,
  }
}
export function yhhInput(val, this_) {
  console.log(val)
  let v = Number(val).toFixed(2)
  if (v < 0 || v == NaN) {
    this_.form.discounted_amount = ''
  }
  console.log(v)

  if (val != '') {
    this_.form.receiv_money = (
      Number(v) -
      this_.form.refund_amount -
      Number(this_.form.support_amount) -
      Number(this_.form.prize_amount) -
      Number(this_.form.cash_amount)
    ).toFixed(2)
  } else {
    this_.form.receiv_money = Number(
      Number(this_.form.sale_amount) -
        Number(this_.form.refund_amount) -
        Number(this_.form.support_amount) -
        Number(this_.form.prize_amount) -
        Number(this_.form.cash_amount)
    ).toFixed(2)
  }
}

export function yhInput(val, this_) {
  console.log(val)
  let v = Number(val).toFixed(2)
  if (v < 0 || v == NaN) {
    this_.form.discount_amount = ''
  }
  // sale_amount
  if (val != '') {
    this_.form.discounted_amount = (
      Number(this_.form.sale_amount) - Number(v)
    ).toFixed(2)
    this_.form.receiv_money = Number(
      Number(this_.form.discounted_amount).toFixed(2) -
        Number(this_.form.refund_amount).toFixed(2) -
        Number(this_.form.support_amount).toFixed(2) -
        Number(this_.form.prize_amount).toFixed(2) -
        Number(this_.form.cash_amount).toFixed(2)
    ).toFixed(2)
  } else {
    this_.form.receiv_money = Number(
      Number(this_.form.sale_amount).toFixed(2) -
        Number(this_.form.refund_amount).toFixed(2) -
        Number(this_.form.support_amount).toFixed(2) -
        Number(this_.form.prize_amount).toFixed(2) -
        Number(this_.form.cash_amount).toFixed(2)
    ).toFixed(2)
  }
}

export function receiv(this_) {
  console.log('receiv', this_.form)
  this_.form.receiv_money = Number(
    Number(this_.form.sale_amount).toFixed(2) -
      Number(this_.form.discount_amount).toFixed(2) -
      Number(this_.form.refund_amount).toFixed(2) -
      Number(this_.form.support_amount).toFixed(2) -
      Number(this_.form.prize_amount).toFixed(2) -
      Number(this_.form.cash_amount).toFixed(2)
  ).toFixed(2)
}

// import { yhhInput, utils } from '@/utils/AOform.js'

// watch
// 'form.receiv_money'(val) {
// let c = JSON.parse(JSON.stringify(this.form.receiv_money))
// if (this.form.account_type == 1) {
//   this.xssk[0].money = Number(c).toFixed(2)
// } else {
//   this.xssk[0].money = 0
// }
// },
// utilsF() {
//   utils(this.list, this)
// },
// yhhInputF(val) {
//   yhhInput(val, this)
// },

// 表格合计
// getSummaries(param) {
//   const { columns, data } = param
//   console.log(columns, data)
//   const sums = []
//   columns.forEach((item) => {
//     sums.push('')
//   })
//   let nums = 0
//   sums[0] = '总价'
//   data.forEach((item) => {
//     if (item.goods_type != '') {
//       let num = Number(
//         (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
//       )
//       nums += num
//     }
//   })
//   sums[9] = nums + '元'
//   return sums
// },
